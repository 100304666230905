@use 'sass:math';

:root {
	--title-font: 'Inter', sans-serif;
	--main-font: 'Inter', sans-serif;
	
	/* --accent: #5ec63f;
	--accent-hover: #71db50;
	--accent-active: #4eb42f; */
	--title-color: #212529;
	--text-color: #5F6472;
	--text-color-05: rgba(94, 100, 115, 0.5);
	--text-color-03: rgba(94, 100, 115, 0.3);
	--link-color: #4B68FF;
	--light-grey: #AEB6CE;
	--grey: #97A0BB;

	--white: #FFF;
	--black: #000;

	--container-padding: 15px;
}

html {
	font-size: 16px;
	height: calc(var(--vh,1vh) * 100);
	min-width: 320px;

	background: #F3F4F8;
	overflow: hidden !important;
	
}

body {

	height: calc(var(--vh,1vh) * 100);
	overflow: hidden !important;
	min-width: 320px;

	font-family: var(--main-font);
	font-weight: 400;
	line-height: 150%;
	font-size: 0.875rem;

	scroll-behavior: smooth;

	@include _992 {
		display: flex;
		flex-direction: column;
	}

	.simplebar-scrollbar.simplebar-visible::before {
		border-radius: 0;
		background: #5F6472;
		opacity: .1;
		//left: 2px; top: 2px; right: 0; bottom: 0;
	}

	.simplebar-track.simplebar-horizontal {
		height: 9px;
	}

	.simplebar-track.simplebar-vertical {
		width: 9px;
	}

	//background: #212529;

	&.is-mobile-menu-active {
		overflow: hidden;
	}
	
	//&:not(.is-init) .ss-content, &:not(.is-init) .drop-down-2__block {
	//	display: none !important;
	//}
}

@keyframes fadeIn {
	from {
		opacity: 0; visibility: hidden;
	}

	to {
		opacity: 1; visibility: visible;
	}
}

@keyframes fadeOut2 {
	to {
		opacity: 0; visibility: hidden;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1; visibility: visible;
	}
	to {
		opacity: 0; visibility: hidden;
	}
}

@keyframes fadeIn2 {
	to {
		opacity: 1; visibility: visible;
	}
}

.fade-in {
	animation: fadeIn2 0.3s ease 0s 1 normal forwards;
}

.fade-out {
	animation: fadeOut2 0.3s ease 0s 1 normal forwards;
}

.fade-in-2 {
	animation: fadeIn 0.3s ease 0s 1 normal forwards;
}

.fade-out-2 {
	animation: fadeOut 0.3s ease 0s 1 normal forwards;
}

a {
	color: var(--link-color);
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-skip-ink: none;
}

.image-aspect-ratio {
	width: 100%; position: relative;
	padding-top: var(--padding-aspect-ratio);
}

.image-aspect-ratio img {
	position: absolute;
	left: 0; top: 0;
	width: 100%; height: 100%;
	object-fit: cover;
}

section {
	position: relative;
	z-index: 2;
}

.wrapper {
	
	//overflow-x: hidden;
	height: calc(var(--vh,1vh) * 100);
	
	@include _992 {
		flex: 1 1 auto;

		display: flex;
		flex-direction: column;
		flex-direction: row;
	}
}

.login-wrapper .main {
	padding: 0;

	@include _992 {
		padding: 0;
	}

	@media (min-width: 1440px) {
		padding: 0;
	}
}

/* .aside {
	width: 230px;
} */

.main {
	//overflow: hidden;

	flex: 1 0 auto;
	position: relative;
	
	animation: fadeIn 0.3s ease 0.3s 1 normal forwards;
	opacity: 0;

	padding: 15px 15px var(--drop-down-size, 30px);

	&.is-none-padding {
		padding: 0;
	}
	
	&.is-none-aside {
		height: calc(var(--vh,1vh) * 100);
		width: 100vw;
	}

	&:not(.is-none-aside) {
		//height: calc(var(--vh,1vh) * 100 - 70px);

		width: 100vw;
		@include _992 {
			width: calc(100vw - var(--aside-width, 230px) - 30px - 14px);
			transition: var(--transition-width, none);
		}

		@media (min-width: 1440px) {
			width: calc(100vw - var(--aside-width, 230px) - 50px - 50px);
		}
	}

	@include _992 {
		height: calc(var(--vh,1vh) * 102);
		&:not(.is-none-aside) {
			height: calc(var(--vh,1vh) * 102);
		}
	}

	overflow-y: auto; overflow-x: hidden;

	& > .simplebar-track > .simplebar-scrollbar::before {
		opacity: .3;
	}

	& > .simplebar-track.simplebar-vertical {
		z-index: -1;
	}

	/* & > .simplebar-track.simplebar-vertical {
		right: 100px;
	} */

	@include _992 {
		padding: 30px 15px var(--drop-down-size, 30px) 30px;
	}

	@media (min-width: 1440px) {
		padding: 30px 50px var(--drop-down-size, 30px);
	}
}

.container {
	padding-left: var(--container-padding);
	padding-right: var(--container-padding);
	
	@media (min-width: #{$container}px) {
		padding-left: max(var(--container-padding), calc(50% - #{math.div($container, 2)}px));
		padding-right: max(var(--container-padding), calc(50% - #{math.div($container, 2)}px));
	}
}

.btn {
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	svg {
		flex: 0 0 15px;
		margin-left: 10px;
	}
	
	font-size: 0.875rem;
	line-height: 100%;
	font-weight: 500;
	text-decoration: none;

	padding: 14px 5px;
	@media (min-width: 430px) {
		padding: 14px 25px;
	}

	background: var(--accent);
	border: 1px solid var(--accent);
	
	color: var(--theme-text-color);

	&.is-disabled {
		opacity: .5;
		pointer-events: none; user-select: none;
	}

	&.is-transparent {
		color: var(--theme-text-color-2);
		background: transparent;
		border: 1px solid var(--accent);
		@media (any-hover: hover) {
			transition: background .3s ease, border-color .3s ease, color .2s ease, opacity .2s ease;
			&:hover {
				background: var(--accent);
				border-color: var(--accent);
				color: var(--theme-text-color);
			}
			
			&:active {
				background: var(--accent-dark);
				border-color: var(--accent-dark);
				color: var(--theme-text-color);
			}
		}
	}

	&.is-min-on-mob {
		font-size: 0;
		svg {
			margin-left: 0;
			@include _992 {
				margin-left: 10px;
			}
		}

		padding: 14px 15px;

		@include _992 {
			font-size: 0.875rem;
			padding: 14px 25px;
		}
	}

	&.is-grey {
		background: var(--grey);
		border: 1px solid var(--grey);
		color: var(--white);

		&.is-transparent {
			background: transparent;
			color: var(--grey);
		}

		@media (any-hover: hover) {
			transition: background .3s ease, border-color .3s ease, color .2s ease, opacity .2s ease;
			&:hover {
				background: var(--accent);
				border-color: var(--accent);
				&.is-transparent {
					background: transparent;
					color: var(--theme-text-color-2);
				}
			}
			
			&:active {
				background: var(--accent-dark);
				border-color: var(--accent-dark);
				&.is-transparent {
					background: transparent;
					color: var(--theme-text-color-2);
				}
			}
		}
	}

	&.is-success {
		background: #27AE60;
		border: 1px solid #27AE60;
		color: var(--white);

		&.is-transparent {
			background: transparent;
			color: #27AE60;
		}

		@media (any-hover: hover) {
			transition: background .3s ease, border-color .3s ease, color .2s ease, opacity .2s ease;
			&:hover {
				background: #29ba66;
				border-color: #29ba66;
				&.is-transparent {
					color: var(--white);
				}
			}
			
			&:active {
				background: #21a859;
				border-color: #21a859;
				&.is-transparent {
					color: var(--white);
				}
			}
		}
	}

	&.is-error {
		background: #EF3129;
		border: 1px solid #EF3129;
		color: var(--white);

		&.is-transparent {
			background: transparent;
			color: #EF3129;
		}

		@media (any-hover: hover) {
			transition: background .3s ease, border-color .3s ease, color .2s ease, opacity .2s ease;
			&:hover {
				background: #FF0A00;
				border-color: #FF0A00;
				&.is-transparent {
					color: var(--white);
				}
			}
			
			&:active {
				background: #CB241D;
				border-color: #CB241D;
				&.is-transparent {
					color: var(--white);
				}
			}
		}
	}

	@media (any-hover: hover) {
		transition: background .3s ease, border-color .3s ease, color .2s ease, opacity .2s ease;
		&:hover {
			background: var(--accent-light);
			//color: var(--white);
		}
		
		&:active {
			background: var(--accent-dark);
			//color: var(--white);
		}
	}

	border-radius: 0;
}

.color-alternative {
	.btn {
		color: var(--text-color);
		
	}
}

.visually-hidden {
	position: absolute;
	width: 1px; height: 1px;
	margin: -1px; padding: 0;
	border: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.visible-on-mob {
	@include _992 {
		display: none !important;
	}
}

.visible-on-desktop {
	@media (max-width: 991px) {
		display: none !important;
	}
}

.drop-down {

	position: relative;
	
	&.is-dark & {
		&__block {
			background: var(--title-color);
			box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40), 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
		}

		&__list a {
			color: var(--white);

			@media (any-hover: hover) {
				transition: background .2s ease;
				&:hover {
					background: var(--text-color-03);
					
				}
				
				&:active {
					background: var(--text-color-05);
				}
			}
		}
	}

	&.is-active & {

		&__target--arrow {
			transform: scaleY(-1);
			color: var(--accent);
		}

		&__block {
			opacity: 1; visibility: visible;
			transform: translate(0,10px);
		}
	}

	&.is-right & {
		&__block {
			left: auto; right: 0;
		}
	}

	&.is-right-default & {
		&__block {
			left: auto; right: 0;
		}
	}

	&.is-top-on-mob &__block {
		top: auto; bottom: calc(100% + 10px);
		@include _992 {
			top: calc(100% + 10px); bottom: auto;
		}
	}

	&.is-mode-1 & {
		
		&__list a {
			display: flex;
			align-items: center;

			padding: 15px 25px;

			svg:first-child {
				flex: 0 0 16px;
				margin-right: 10px;
			}
		}
	}

	&.is-bottom & {
		&__block {
			z-index: 2;
			bottom: 100%; top: auto;
			transform: translate(0,-10px);
		}
	}

	// .drop-down__target

	&__target {
		user-select: none;
		&--arrow {
			transition: transform .2s ease, color .2s ease;
		}
	}

	// .drop-down__block

	&__block {
		position: absolute;
		left: 0; top: 100%;
		min-width: 100%;

		background: var(--white);
		box-shadow: 0px 0px 30px 0px rgba(65, 79, 124, 0.08), 0px 4px 5px 0px rgba(65, 79, 124, 0.10);

		transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
		opacity: 0; visibility: hidden;
		z-index: 2;

		max-height: 240px;
		overflow-y: auto;

		.splide.is-initialized, .splide.is-rendered {
			visibility: unset;
		}

		.splide {
			visibility: unset;
		}
	}

	// .drop-down__list

	&__list {
		a {
			display: block;
			padding: 15px;
			user-select: none;

			color: #5F6472;
			text-decoration: none;
			text-align: left;

			@media (any-hover: hover) {
				transition: background .2s ease, color .2s ease;
				&:hover {
					background: rgba(150, 159, 187, 0.102);
					color: var(--accent);
				}
				
				&:active {
					background: rgba(150, 159, 187, 0.2);
					color: var(--accent-dark);
				}
			}
		}
	}
}

.drop-down-2 {

	cursor: pointer;
	&.is-dark & {
		&__block {
			background: var(--title-color);
		}

		&__list a {
			color: var(--white);

			@media (any-hover: hover) {
				transition: background .2s ease;
				&:hover {
					background: var(--text-color-03);
					
				}
				
				&:active {
					background: var(--text-color-05);
				}
			}
		}
	}

	/* &.is-right & {
		&__block {
			left: auto; right: 0;
		}
	} */

	/* &.is-top-on-mob &__block {
		top: auto; bottom: calc(100% + 10px);
		@include _992 {
			top: calc(100% + 10px); bottom: auto;
		}
	} */

	// .drop-down__block

	&__block {
		position: absolute;
		left: var(--x); top: var(--y);
		//transform: translate(-50%,0);
		@include _992 {
			transform: none;
		}

		width: auto;
		min-width: 155px;

		background: var(--white);
		box-shadow: 0px 0px 30px 0px rgba(65, 79, 124, 0.08), 0px 4px 5px 0px rgba(65, 79, 124, 0.10);

		z-index: 2;

		//display: none;
		opacity: 0; visibility: visible;

		&.is-hidden {
			animation: fadeOut 0.3s ease 0s 1 normal forwards;
		}

		&.is-active {
			animation: fadeIn 0.3s ease 0s 1 normal forwards;
		}

		.splide.is-initialized, .splide.is-rendered {
			visibility: unset;
		}

		.splide {
			visibility: unset;
		}
	}

	// .drop-down__list

	&__list {
		a {
			display: flex;
			align-items: center;
			svg:first-child {
				flex: 0 0 15px;
				margin-right: 10px;
			}
			padding: 15px 25px;
			user-select: none;

			color: #5F6472;
			text-decoration: none;
			text-align: start;

			//background: rgba(150, 159, 187, 0.102);
			@media (any-hover: hover) {
				transition: background .2s ease, color .2s ease;
				&:hover {
					background: rgba(150, 159, 187, 0.102);
					color: var(--accent);
				}
				
				&:active {
					background: rgba(150, 159, 187, 0.2);
					color: var(--accent-dark);
				}
			}
		}
	}
}

.drop-down-absolute {

	&__target {
		
		&.is-active &--arrow {
			transform: scaleY(-1);
		}

		&--arrow {
			transition: transform .3s ease;
		}
	}

	&__block {
		position: absolute;
		left: var(--x); top: var(--y);
		&.is-right {
			right: var(--x); left: auto;
		}

		&.is-right-default {
			left: auto; right: var(--x);
		}

		transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
		opacity: 0; visibility: hidden;

		background: var(--white);

		box-shadow: 0px 0px 30px 0px rgba(65, 79, 124, 0.08), 0px 4px 5px 0px rgba(65, 79, 124, 0.10);

		&.is-active {
			opacity: 1; visibility: visible;
			transform: translate(0,10px);
		}

	}
}

.splide__arrow--prev svg {
	transform: none;
}

.splide__arrow:focus-visible {
	outline: none;
}

.custom-scrollbar {
	.simplebar-track.simplebar-vertical {
		width: 5px;

		.simplebar-scrollbar::before {
			top: 0; left: 0;
			width: 100%;
			border-radius: 0;
			background: #5F6472;
			
		}
	}
}

.change-title {
	display: grid;
	> * {
		grid-area: 1/1;

		transition: opacity .3s ease, visibility .3s ease;

		&:nth-child(1) {
			opacity: 1; visibility: visible;
		}

		&:nth-child(2) {
			opacity: 0; visibility: hidden;
		}
	}

	&.is-active > * {
		&:nth-child(1) {
			opacity: 0; visibility: hidden;
		}

		&:nth-child(2) {
			opacity: 1; visibility: visible;
		}
	}
}

.message {

	position: absolute;
	left: 0; top: 0;
	width: 100%;

	background: var(--accent);

	padding: 7px 15px;

	display: grid;
	grid-template-columns: 15px auto 15px;
	gap: 10px;

	z-index: 4;

	// .message__text

	&__text {
		font-size: 0.875rem;
		line-height: 120%;
		font-weight: 500;

		grid-column: 1/3; grid-row: 1;

		text-align: center;

		padding: 0 20px;
		color: var(--white);
	}

	// .message__close

	&__close {
		color: var(--white);

		display: flex;
		align-items: center;
		justify-content: center;

		background: transparent;

		grid-row: 1; grid-column: 3;
	}
}

.back-btn {
	height: 40px;
	@include _992 {
		height: 45px;
		flex: 0 0 56px; width: 56px;
	}
	border: 1px solid var(--accent);
	color: var(--accent);

	span {
		padding-left: 5px;

		font-size: 0.875rem;
	}

	background: transparent;
}

.drop-down-2 .drop-down-2__block {
	display: none;
}

.react-datepicker__day-name {
	text-transform: uppercase;
}
